'use strict';
  
$(function(){
    //スマホ時　アコーディオン
    $('.about-toggle-btn').each(function(){
        $(this).on('click',function(){
            if(navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/)){
                $(this).next('div').slideToggle();
                return false;
            }
        });
    });
    $('.faq-toggle-btn').each(function(){
        $(this).on('click',function(){
            if(navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/)){
                $(this).next('dd').slideToggle();
                return false;
            }
        });
    });
});
